<template>
  <b-container
    v-if="loaded"
    fluid
  >
    <b-row class="d-flex justify-content-center p-3">
      <b-col md="6">
        <h1>{{ $route.params.id ? 'Edit Banner' : 'New Banner' }}</h1>
        <hr>
        <b-form @submit="onSubmit">
          <b-form-group
            label="Name"
            label-size="lg"
            label-class="font-weight-bold pt-0"
          >
            <b-row>
              <b-col
                md="6"
              >
                <b-form-input
                  v-model="banner.name"
                  required
                ></b-form-input>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            label="Images"
            label-size="lg"
            label-class="font-weight-bold pt-0"
          >
            <FileUpload
              class="mb-3"
              :value="images"
              @input="uploadedImage"
            />
            <div v-if="images.length">
              <draggable
                v-model="images"
                class="row"
              >
                <div
                  v-for="(image, index) in images"
                  :key="image.id"
                  class="col-3"
                  style="width:150px;position:relative;"
                >
                  <b-img
                    :src="`${$config.cdnUrl}${image.src}`"
                    thumbnail
                  />
                  <div
                    class="remove-icon-wrapper"
                  >
                    <span
                      class="fa-stack"
                      title="Remove"
                      @click="images.splice(index, 1)"
                    >
                      <i class="fas fa-circle fa-stack-2x text-danger" />
                      <i class="fas fa-times fa-stack-1x fa-inverse" />
                    </span>
                  </div>
                  <div>
                    <label>Image Link</label>
                    <b-form-input
                      v-model="images[index].link"
                      required
                    ></b-form-input>
                  </div>
                </div>
              </draggable>
            </div>
          </b-form-group>
          <b-button
            class="mr-3"
            type="submit"
            variant="primary"
          >Submit</b-button>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { makeGetRequest } from '@/util'
import draggable from 'vuedraggable'
import FileUpload from '@/components/FileUpload'

export default {
  name: 'CreateEditBanner',
  components: {
    draggable,
    FileUpload
  },
  data () {
    return {
      banner: {
        name: '',
        status: 'published'
      },
      images: [],
      loaded: false
    }
  },
  methods: {
    uploadedImage (value) {
      value.link = null
      this.images.push(value)
    },
    async onSubmit (e) {
      e.preventDefault()
      const { banner, images } = this
      banner.images = images
      try {
        let url = `${this.$config.apiUrl}/banners`
        let method = 'post'
        if (this.$route.params.id) {
          url += `/${this.$route.params.id}`
          method = 'put'
        }
        await this.axios({
          method,
          url,
          data: banner
        })
        this.$router.replace('/admin/banners')
      } catch (error) {
        // console.log(error)
      }
    }
  },
  async created () {
    try {
      if (this.$route.params.id) {
        const url = `${this.$config.apiUrl}/banners/${this.$route.params.id}`
        const {
          images,
          ...banner
        } = await makeGetRequest(url)
        this.banner = banner
        images.forEach((item) => {
          item.link = item.pivot.link // eslint-disable-line
        })
        this.images = images
      }
    } catch (error) {
      // console.error(error)
    } finally {
      this.loaded = true
    }
  }
}
</script>

<style>
</style>
